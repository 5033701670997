import * as S from "./styles";
import { StyledLink, ExternalLink } from "../../Links/Links";
import { AccordionProps } from "../MobileDropdown/MobileDropdown";

export const Accordion = ({ subColor, featuresList, item }: AccordionProps) => (
  <S.AccordionContainer subColor={subColor}>
    <S.AccordionHeader>{featuresList[item]?.navHeader}</S.AccordionHeader>
    <S.AccordionItems>
      {featuresList[item]?.dropdown?.map((dropdownItem: any, index: number) =>
        featuresList[dropdownItem]?.external === true ? (
          <ExternalLink
            href={featuresList[dropdownItem]?.url}
            target='_blank'
            rel='noopener noreferrer'
            key={featuresList[dropdownItem]?.title + index}
          >
            {featuresList[dropdownItem]?.title}
          </ExternalLink>
        ) : (
          <StyledLink
            to={dropdownItem}
            key={featuresList[dropdownItem]?.title + index}
          >
            {featuresList[dropdownItem]?.title}
          </StyledLink>
        )
      )}
    </S.AccordionItems>
  </S.AccordionContainer>
);
